import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  // {
  //   path: 'product/:productId',
  //   loadChildren: () => import('./pages/product/page.module').then(m => m.ProductPageModule)
  // },
  // {
  //   path: 'cart',
  //   loadChildren: () => import('./pages/cart/page.module').then(m => m.CartPageModule)
  // },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./pages/home/page.module').then(m => m.HomePageModule)
  // },
  // {
  //   path: 'code',
  //   loadChildren: () => import('./pages/search-by-code/page.module').then(m => m.SearchByCodePageModule)
  // },
  // {
  //   path: 'payment',
  //   loadChildren: () => import('./pages/payment/page.module').then(m => m.PaymentPageModule)
  // },
  // {
  //   path: 'settings',
  //   loadChildren: () => import('./pages/settings/page.module').then(m => m.SettingsPageModule)
  // },
  {
    path: 'carts',
    loadChildren: () => import('./pages/carts/page.module').then(m => m.CartsPageModule)
  },
  {
    path: 'cart/:cartId',
    loadChildren: () => import('./pages/cart/page.module').then(m => m.CartPageModule)
  },
  {
    path: 'address',
    loadChildren: () => import('./pages/address-edit/page.module').then(m => m.AddressEditPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/page.module').then(m => m.ProductsPageModule)
  },
  {
    path: 'product/:productId',
    loadChildren: () => import('./pages/product/page.module').then(m => m.ProductPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/page.module').then(m => m.OrdersPageModule)
  },
  {
    path: 'order/:orderId',
    loadChildren: () => import('./pages/order/page.module').then(m => m.OrderPageModule)
  },
  {
    path: 'customer/edit',
    loadChildren: () => import('./pages/customer-edit/page.module').then(m => m.CustomerEditPageModule),
  },
  {
    path: 'customer/edit/:customerId',
    loadChildren: () => import('./pages/customer-edit/page.module').then(m => m.CustomerEditPageModule),
  },
  {
    path: 'customer/:customerId',
    loadChildren: () => import('./pages/customer/page.module').then(m => m.CustomerPageModule),
  },
  {
    path: 'customers',
    loadChildren: () => import('./pages/customers/page.module').then(m => m.CustomersPageModule)
  },
  {
    path: 'delays',
    loadChildren: () => import('./pages/delays/page.module').then(m => m.DelaysPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/page.module').then(m => m.StartPageModule)
  },
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
