import {EventEmitter, Injectable} from '@angular/core';
import {MenuController, ToastController} from '@ionic/angular';
import {AlertController} from '@ionic/angular';
import {HelperService} from './helper.service';
import {LoadingController} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  appReady = false;
  toastController: ToastController;
  menu: MenuController;
  loadingController: LoadingController;
  loading: any;
  isLoading = false;
  loadingCounter = 0;
  employeeToken = '23024ff6dd6b53bebc2a65b33cc36a35';

  private alertController: AlertController;

  constructor(
    menu: MenuController,
    alertController: AlertController,
    toastController: ToastController,
    loadingController: LoadingController,
  ) {
    this.menu = menu;
    this.alertController = alertController;
    this.toastController = toastController;
    this.loadingController = loadingController;
  }

  async showLoading() {
    this.loadingCounter++;

    // if (!this.isLoading && !this.startPage) {
    if (!this.isLoading) {

      this.isLoading = true;

      this.loading = await this.loadingController.create({
        message: 'Loading',
        showBackdrop: true
      });
      await this.loading.present();
    }
  }

  async hideLoading(all = false) {
    this.loadingCounter--;

    if (all) {
      this.loadingCounter = 0;
    }

    if (this.loadingCounter <= 0) {

      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }

      this.loadingCounter = 0;

      this.isLoading = false;
      return await this.loadingController.dismiss(null, 'cancel');
    }

    return null;
  }

  async showAlert(title: string, desc: string, error: boolean) {
    const alert = await this.alertController.create({
      header: title,
      message: desc,
      buttons: [ 'Ok' ]
    });

    await alert.present();
  }

  openMenu() {
    this.menu.open();
  }

  async showToast(text: string) {
    const toast = await this.toastController.create({
      message: text,
      duration: 2000
    });
    toast.present();
  }

}
